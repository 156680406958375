import React from 'react';
import {
  Form,
  Row,
  Col,
  Button,
  Collapse,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import {
  FaSearch,
  FaRedoAlt,
  FaQuestionCircle,
  FaDollarSign,
} from 'react-icons/fa';
import './filtro.css';

interface FiltroBuscaProps {
  termoBusca: string;
  setTermoBusca: (value: string) => void;
  precoMinimo: string;
  setPrecoMinimo: (value: string) => void;
  precoMaximo: string;
  setPrecoMaximo: (value: string) => void;
  filtroAvaliacao: string;
  setFiltroAvaliacao: (value: string) => void;
  filtroFrete: string;
  setFiltroFrete: (value: string) => void;
  filtroCategoria: string;
  setFiltroCategoria: (value: string) => void;
  categorias: { id: string; name: string }[];
  filtroCondicao: string;
  setFiltroCondicao: (value: string) => void;
  filtroMarca: string;
  setFiltroMarca: (value: string) => void;
  marcas: string[];
  filtroLocalidade: string;
  setFiltroLocalidade: (value: string) => void;
  localidades: string[];
  filtroDesconto: boolean;
  setFiltroDesconto: (checked: boolean) => void;
  buscarProdutos: (e: React.FormEvent<HTMLFormElement>) => void;
  limparFiltros: () => void;
  ordenacao: string;
  setOrdenacao: (value: string) => void;
}

const FiltroBusca: React.FC<FiltroBuscaProps> = ({
  termoBusca,
  setTermoBusca,
  precoMinimo,
  setPrecoMinimo,
  precoMaximo,
  setPrecoMaximo,
  filtroAvaliacao,
  setFiltroAvaliacao,
  filtroFrete,
  setFiltroFrete,
  filtroCategoria,
  setFiltroCategoria,
  categorias,
  filtroCondicao,
  setFiltroCondicao,
  filtroMarca,
  setFiltroMarca,
  marcas,
  filtroLocalidade,
  setFiltroLocalidade,
  localidades,
  filtroDesconto,
  setFiltroDesconto,
  buscarProdutos,
  limparFiltros,
  ordenacao,
  setOrdenacao,
}) => {
  const [open, setOpen] = React.useState(false);

  return (
    <Form onSubmit={buscarProdutos} className="filtro-busca sticky-form">
      <Row className="mb-3">
        <Col lg={5} md={3} sm={12} xs={12}>
          <Form.Group controlId="termoBusca">
            <InputGroup>
              <Form.Control
                type="text"
                value={termoBusca}
                onChange={(e) => setTermoBusca(e.target.value)}
                placeholder="Digite um termo de busca..."
                required
              />
              <InputGroup.Text>
                <FaSearch />
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>
        </Col>
        <Col lg={2} md={3} sm={12} xs={12}>
          <Button
            onClick={() => setOpen(!open)}
            aria-controls="collapse-filtros"
            aria-expanded={open}
            variant="outline-primary"
            className="w-100 mt-2 mt-md-0"
          >
            {open ? 'Esconder Filtros' : 'Mostrar Filtros'}
          </Button>
        </Col>
        <Col lg={3} md={3} sm={12} xs={12}>
          <Button
            type="submit"
            className="w-100 d-flex align-items-center justify-content-center"
          >
            <FaSearch className="me-2" /> Buscar
          </Button>
        </Col>
        <Col lg={2} md={3} sm={12} xs={12}>
          <Button
            variant="secondary"
            onClick={limparFiltros}
            className="w-100 d-flex align-items-center justify-content-center"
          >
            <FaRedoAlt className="me-2" /> Limpar Filtros
          </Button>
        </Col>
      </Row>
      <Collapse in={open}>
        <div id="collapse-filtros">
          <Row className="mb-3">
            <Col lg={2} md={4} sm={12} xs={12}>
              <Form.Group controlId="precoMinimo">
                <Form.Label>Preço Mínimo</Form.Label>
                <Form.Control
                  type="number"
                  value={precoMinimo}
                  onChange={(e) => setPrecoMinimo(e.target.value)}
                  placeholder="Mín."
                  min="0"
                />
              </Form.Group>
            </Col>
            <Col lg={2} md={4} sm={12} xs={12}>
              <Form.Group controlId="precoMaximo">
                <Form.Label>Preço Máximo</Form.Label>
                <Form.Control
                  type="number"
                  value={precoMaximo}
                  onChange={(e) => setPrecoMaximo(e.target.value)}
                  placeholder="Máx."
                  min="0"
                />
              </Form.Group>
            </Col>
            <Col lg={2} md={4} sm={12} xs={12}>
              <Form.Group controlId="filtroFrete">
                <Form.Label>Frete</Form.Label>
                <Form.Select
                  value={filtroFrete}
                  onChange={(e) => setFiltroFrete(e.target.value)}
                >
                  <option value="">Todos</option>
                  <option value="gratis">Frete Grátis</option>
                  <option value="local_pickup">Retirada no Local</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col lg={2} md={4} sm={12} xs={12}>
              <Form.Group controlId="filtroAvaliacao">
                <Form.Label>Avaliação do Vendedor</Form.Label>
                <Form.Select
                  value={filtroAvaliacao}
                  onChange={(e) => setFiltroAvaliacao(e.target.value)}
                >
                  <option value="">Todos</option>
                  <option value="5_green">5 estrelas</option>
                  <option value="4_light_green">4 estrelas ou mais</option>
                  <option value="3_yellow">3 estrelas ou mais</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col lg={2} md={4} sm={12} xs={12}>
              <Form.Group controlId="filtroCategoria">
                <Form.Label>Categoria</Form.Label>
                <Form.Select
                  value={filtroCategoria}
                  onChange={(e) => setFiltroCategoria(e.target.value)}
                >
                  <option value="">Todas</option>
                  {categorias.map((categoria) => (
                    <option key={categoria.id} value={categoria.id}>
                      {categoria.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col lg={2} md={4} sm={12} xs={12}>
              <Form.Group controlId="filtroCondicao">
                <Form.Label>Condição</Form.Label>
                <Form.Select
                  value={filtroCondicao}
                  onChange={(e) => setFiltroCondicao(e.target.value)}
                >
                  <option value="">Todas</option>
                  <option value="new">Novo</option>
                  <option value="used">Usado</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={4} xs={12}>
              <Form.Group controlId="filtroMarca">
                <Form.Label>
                  Marca{' '}
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-marca">
                        Essa informação é baseada após a pesquisa realizada.
                        Para usar, primeiro faça uma busca e depois selecione a
                        marca desejada.
                      </Tooltip>
                    }
                  >
                    <span>
                      <FaQuestionCircle />
                    </span>
                  </OverlayTrigger>
                </Form.Label>
                <Form.Select
                  value={filtroMarca}
                  onChange={(e) => setFiltroMarca(e.target.value)}
                  disabled={marcas.length === 0}
                >
                  <option value="">Todas</option>
                  {marcas.map((marca) => (
                    <option key={marca} value={marca}>
                      {marca}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={4} xs={12}>
              <Form.Group controlId="filtroLocalidade">
                <Form.Label>
                  Localidade{' '}
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-localidade">
                        Essa informação é baseada após a pesquisa realizada.
                        Para usar, primeiro faça uma busca e depois selecione a
                        localidade desejada.
                      </Tooltip>
                    }
                  >
                    <span>
                      <FaQuestionCircle />
                    </span>
                  </OverlayTrigger>
                </Form.Label>
                <Form.Select
                  value={filtroLocalidade}
                  onChange={(e) => setFiltroLocalidade(e.target.value)}
                  disabled={localidades.length === 0}
                >
                  <option value="">Todas</option>
                  {localidades.map((localidade) => (
                    <option key={localidade} value={localidade}>
                      {localidade}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={4} xs={12}>
              <Form.Group controlId="ordenacao">
                <Form.Label>
                  Ordenar por{' '}
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-ordenacao">
                        Selecione o critério de ordenação dos resultados.
                      </Tooltip>
                    }
                  >
                    <span>
                      <FaQuestionCircle />
                    </span>
                  </OverlayTrigger>
                </Form.Label>
                <Form.Select
                  value={ordenacao}
                  onChange={(e) => setOrdenacao(e.target.value)}
                >
                  <option value="">Relevância</option>
                  <option value="price_asc">Menor Preço</option>
                  <option value="price_desc">Maior Preço</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={4} xs={12} className="d-flex align-items-center">
              <Form.Check
                type="checkbox"
                label={
                  <>
                    <FaDollarSign className="me-2" />
                    Com desconto
                  </>
                }
                checked={filtroDesconto}
                onChange={(e) => setFiltroDesconto(e.target.checked)}
                className="custom-checkbox"
              />
            </Col>
          </Row>
        </div>
      </Collapse>
    </Form>
  );
};

export default FiltroBusca;
