import React from 'react';
import { Table, Button, Form, Pagination } from 'react-bootstrap';
import { Produto } from './type';
import { formatCurrency } from '../../utils';

interface ResultadosBuscaProps {
  produtosFiltrados: Produto[];
  limite: number;
  setLimite: (limite: number) => void;
  exportToExcel: (produtosParaExportar: Produto[]) => void; // Passar os produtos para exportação
  abrirModalComparacao: () => void;
  produtosComparacao: Produto[];
  selecionarTodos: boolean;
  handleSelecionarTodos: (e: React.ChangeEvent<HTMLInputElement>) => void;
  produtosSelecionados: string[];
  handleProdutoSelect: (id: string) => void;
  removerComparacao: (id: string) => void;
  adicionarComparacao: (produto: Produto) => void;
  paginaAnterior: () => void;
  proximaPagina: () => void;
  offset: number;
  totalPaginas: number;
  paginaAtual: number;
}

const ResultadosBusca: React.FC<ResultadosBuscaProps> = ({
  produtosFiltrados,
  limite,
  setLimite,
  exportToExcel,
  abrirModalComparacao,
  produtosComparacao,
  selecionarTodos,
  handleSelecionarTodos,
  produtosSelecionados,
  handleProdutoSelect,
  removerComparacao,
  adicionarComparacao,
  paginaAnterior,
  proximaPagina,
  offset,
  totalPaginas,
  paginaAtual,
}) => {
  const handleExport = () => {
    if (produtosSelecionados.length > 0) {
      // Filtra os produtos que foram selecionados
      const produtosParaExportar = produtosFiltrados.filter((produto) =>
        produtosSelecionados.includes(produto.id)
      );
      exportToExcel(produtosParaExportar);
    } else {
      // Se nenhum produto for selecionado, exporta todos os produtos filtrados
      exportToExcel(produtosFiltrados);
    }
  };

  return (
    <>
      {produtosFiltrados.length > 0 ? (
        <>
          <div className="d-flex justify-content-between align-items-center mt-4">
            <h2>Resultados da Busca</h2>
            <div className="d-flex align-items-center gap-2">
              <Form.Label className="mb-0">Itens por página:</Form.Label>
              <Form.Select
                value={limite}
                onChange={(e) => setLimite(Number(e.target.value))}
                style={{ width: '100px', marginTop: '10px' }}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
              </Form.Select>
              <Button onClick={handleExport}>Exportar para Excel</Button>
              <Button
                variant="success"
                onClick={abrirModalComparacao}
                disabled={produtosComparacao.length < 2}
              >
                Comparar Produtos ({produtosComparacao.length})
              </Button>
            </div>
          </div>
          <Table striped bordered hover responsive className="mt-3">
            <thead>
              <tr>
                <th>
                  <Form.Check
                    type="checkbox"
                    checked={selecionarTodos}
                    onChange={handleSelecionarTodos}
                    style={{ transform: 'scale(1.5)' }}
                  />
                </th>
                <th>Imagem</th>
                <th>Produto</th>
                <th>Preço Vendido</th>
                <th>Preço Original</th>
                <th>Vendedor</th>
                <th>Condição</th>
                <th>Disponibilidade</th>
                <th>Frete Grátis</th>
                <th>Marca</th>
                <th>Localidade</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {produtosFiltrados.map((produto) => (
                <tr key={produto.id}>
                  <td>
                    <Form.Check
                      type="checkbox"
                      checked={produtosSelecionados.includes(produto.id)}
                      onChange={() => handleProdutoSelect(produto.id)}
                      style={{ transform: 'scale(1.5)' }}
                    />
                  </td>
                  <td>
                    <img
                      src={produto.thumbnail}
                      alt={produto.title}
                      width="50"
                      height="50"
                    />
                  </td>
                  <td
                    style={{
                      maxWidth: '800px',
                      wordWrap: 'break-word',
                      whiteSpace: 'normal',
                    }}
                  >
                    {produto.title}
                  </td>
                  <td>{formatCurrency(produto?.price)}</td>
                  <td>
                    {produto?.original_price
                      ? formatCurrency(produto.original_price)
                      : 'N/A'}
                  </td>
                  <td>{produto.seller?.nickname || ''}</td>
                  <td>{produto.condition === 'new' ? 'Novo' : 'Usado'}</td>
                  <td>
                    {produto.available_quantity > 0 ? 'Disponível' : 'Esgotado'}
                  </td>
                  <td>{produto.shipping.free_shipping ? 'Sim' : 'Não'}</td>
                  <td
                    style={{
                      maxWidth: '100px',
                      wordWrap: 'break-word',
                      whiteSpace: 'normal',
                    }}
                  >
                    {produto.attributes?.find((attr) => attr.id === 'BRAND')
                      ?.value_name || 'N/A'}
                  </td>
                  <td>{produto.seller_address?.city?.name || 'N/A'}</td>
                  <td>
                    <a
                      href={produto.permalink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Ver Anúncio
                    </a>
                    <br />
                    {produtosComparacao.some((p) => p.id === produto.id) ? (
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => removerComparacao(produto.id)}
                        className="mt-1"
                      >
                        Remover Comparação
                      </Button>
                    ) : (
                      <Button
                        variant="primary"
                        size="sm"
                        onClick={() => adicionarComparacao(produto)}
                        className="mt-1"
                      >
                        Adicionar à Comparação
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div>Total de resultados: {produtosFiltrados.length}</div>
        </>
      ) : (
        <div className="mt-4 text-center">
          <h6 className="text-muted">
            Nenhum resultado encontrado nesta página.
          </h6>
          <p className="text-muted">
            Tente ajustar os filtros de busca ou verifique se digitou
            corretamente.
          </p>
          <img
            src="/logoheader.png"
            alt="Nenhum resultado encontrado"
            style={{ width: '150px', marginTop: '20px' }}
          />
        </div>
      )}

      {/* Botões de navegação exibidos independentemente de ter produtos */}
      <Pagination className="mt-3">
        <Pagination.Prev onClick={paginaAnterior} disabled={offset === 0}>
          Anterior
        </Pagination.Prev>
        <Pagination.Item active>
          Página {paginaAtual} de {totalPaginas}
        </Pagination.Item>
        <Pagination.Next
          onClick={proximaPagina}
          disabled={paginaAtual === totalPaginas}
        >
          Próximo
        </Pagination.Next>
      </Pagination>
    </>
  );
};

export default ResultadosBusca;
