import React from 'react';
import { Form } from 'react-bootstrap';

interface FiltroVendedoresProps {
  vendedores: string[];
  vendedoresSelecionados: string[];
  handleCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const FiltroVendedores: React.FC<FiltroVendedoresProps> = ({
  vendedores,
  vendedoresSelecionados,
  handleCheckboxChange,
}) => {
  return (
    <>
      {vendedores.length > 0 && (
        <div className="container">
          <br />
          <h5 style={{ textAlign: 'center', fontWeight: 'bold' }}>
            Filtrar por Vendedor da Página Atual e não da Base de Dados:
          </h5>
          <br />
          <div className="d-flex flex-wrap">
            {vendedores.map((vendedor) => (
              <div
                key={vendedor}
                style={{
                  marginRight: '30px',
                  marginBottom: '15px',
                  width: '400px',
                }}
              >
                <Form.Check
                  type="checkbox"
                  label={vendedor}
                  value={vendedor}
                  onChange={handleCheckboxChange}
                  checked={vendedoresSelecionados.includes(vendedor)}
                  style={{ transform: 'scale(1.2)' }}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default FiltroVendedores;
