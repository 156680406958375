import Container from 'react-bootstrap/Container';
import { Button, Navbar, Dropdown, ButtonGroup } from 'react-bootstrap';
import {
  FaSignOutAlt,
  FaBars,
  FaDollarSign,
  FaNewspaper,
} from 'react-icons/fa';
import { DiAptana } from 'react-icons/di';
import { useAuth } from '../Private/AuthContext';
import BreadcrumbComponent from '../Breadcrumb/BreadcrumbComponent';
import './Header.css';

interface HeaderProps {
  onToggleSidebar: () => void;
}

export function Header({ onToggleSidebar }: HeaderProps) {
  const { isAuthenticated, logout } = useAuth();

  if (!isAuthenticated) {
    return null;
  }

  return (
    <header>
      <Navbar
        expand="lg"
        className="navbar border-bottom border-body fixed-top bg-white shadow-sm"
      >
        <Container
          fluid
          className="d-flex justify-content-between align-items-center"
        >
          <div className="d-flex align-items-center">
            {/* Hamburger button para o sidebar (lado esquerdo) */}
            <Button
              variant="outline-secondary"
              className="d-md-none d-lg-none" // Esconde em telas md e acima
              onClick={onToggleSidebar}
            >
              <FaBars size={18} />
            </Button>
            <Navbar.Brand href="/" className="me-2">
              <img
                src="/main.png"
                className="navbar-brand-img"
                alt="Logo"
                width="50px"
              />
            </Navbar.Brand>
            <BreadcrumbComponent /> {/* Breadcrumb aqui */}
          </div>

          {/* Menu dropdown hamburger no lado direito para mobile */}
          <div className="d-lg-none d-md-block">
            <Dropdown align="end">
              <Dropdown.Toggle variant="outline-secondary">
                <FaBars size={18} />
              </Dropdown.Toggle>

              <Dropdown.Menu align="end">
                {/* Administração */}
                <Dropdown.Item href="/noticias">
                  <FaNewspaper size={18} className="me-2" />
                  Notícias
                </Dropdown.Item>
                <Dropdown.Item href="/pesquisaMercado">
                  <DiAptana size={18} className="me-2" />
                  Consulta Preço
                </Dropdown.Item>
                <Dropdown.Item href="/Calculadora">
                  <FaDollarSign size={18} className="me-2" />
                  Calculadora de Margem Lucro
                </Dropdown.Item>
                <Dropdown.Item href="/Admin">
                  <DiAptana size={18} className="me-2" />
                  Administração
                </Dropdown.Item>

                {/* Configuração com submenu */}
                <Dropdown drop="down">
                  {' '}
                  {/* Altera para 'down' no mobile */}
                  <Dropdown.Toggle
                    variant="outline-secondary"
                    className="d-flex align-items-center"
                  >
                    <DiAptana size={18} className="me-2" />
                    Configuração
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item href="/Contas">
                      <DiAptana size={18} className="me-2" />
                      Contas
                    </Dropdown.Item>
                    <Dropdown.Item href="/Integracao">
                      <DiAptana size={18} className="me-2" />
                      Integração
                    </Dropdown.Item>
                    <Dropdown.Item href="/Usuario">
                      <DiAptana size={18} className="me-2" />
                      Meu Perfil
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

                {/* Sair */}
                <Dropdown.Item onClick={logout}>
                  <FaSignOutAlt size={18} className="me-2" />
                  Sair
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>

          {/* Menu normal para telas maiores */}
          <div className="d-none d-lg-flex align-items-center">
            <Dropdown as={ButtonGroup} className="me-2">
              {/* Novo botão para Consulta Preço */}
              <Button
                variant="outline-primary"
                className="d-flex align-items-center"
              >
                <DiAptana size={18} className="me-2" />
                Módulos Adicionais
              </Button>
              <Dropdown.Toggle
                split
                variant="outline-primary"
                id="dropdown-split-basic"
              />
              <Dropdown.Menu>
                <Dropdown.Item
                  href="/pesquisaMercado"
                  className="d-flex align-items-center"
                >
                  <FaDollarSign size={18} className="me-2" />
                  Consulta Preço
                </Dropdown.Item>
                <Dropdown.Item
                  href="/Calculadora"
                  className="d-flex align-items-center"
                >
                  <FaNewspaper size={18} className="me-2" />
                  Calculadora de Margem Lucro
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown as={ButtonGroup} className="me-2">
              {/* Novo botão para Consulta Preço */}
              <Button
                variant="outline-primary"
                className="d-flex align-items-center"
              >
                <DiAptana size={18} className="me-2" />
                Administração
              </Button>
              <Dropdown.Toggle
                split
                variant="outline-primary"
                id="dropdown-split-basic"
              />
              <Dropdown.Menu>
                <Dropdown.Item
                  href="/Admin"
                  className="d-flex align-items-center"
                >
                  <DiAptana size={18} className="me-2" />
                  Usuários
                </Dropdown.Item>
                <Dropdown.Item
                  href="/noticias"
                  className="d-flex align-items-center"
                >
                  <FaNewspaper size={18} className="me-2" />
                  Notícias
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            {/* Configuração com submenu */}
            <Dropdown as={ButtonGroup} className="me-2">
              <Button
                variant="outline-secondary"
                className="d-flex align-items-center"
              >
                <DiAptana size={18} className="me-2" />
                Configuração
              </Button>
              <Dropdown.Toggle
                split
                variant="outline-secondary"
                id="dropdown-split-basic"
              />
              <Dropdown.Menu>
                <Dropdown.Item href="/Contas">
                  <DiAptana size={18} className="me-2" />
                  Contas
                </Dropdown.Item>
                <Dropdown.Item href="/Integracao">
                  <DiAptana size={18} className="me-2" />
                  Integração
                </Dropdown.Item>
                <Dropdown.Item href="/Usuario">
                  <DiAptana size={18} className="me-2" />
                  Meu Perfil
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Button
              onClick={logout}
              variant="outline-danger"
              className="d-flex align-items-center"
            >
              <FaSignOutAlt size={18} className="me-2" />
              Sair
            </Button>
          </div>
        </Container>
      </Navbar>
    </header>
  );
}
